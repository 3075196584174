@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.container {
  display: flex;
  margin: -10vw auto auto auto;
  background-color: $pureWhite;
  width: 75%;
  height: 29vw;
  border-radius: 15px;
  margin-bottom: 100px;
}
.text {
  width: 38%;
  font-family: "AndadaPro";
  margin: auto;
  margin-left: 0px;
  color: $greyTextColor;
}
.title {
  color: $greyTextColor;
  text-align: center;
  font-size: 2vw;
  margin-top: 0;
}
.paragraph {
  text-align: center;
  margin: auto;
  width: 82%;
  font-size: 1vw;
}
.imageContainer {
  margin: auto;
  width: 50%;
}
.image {
  display: block;
  width: 100%;
  height: auto;
}
.seeButton {
  display: block;
  font-family: AndadaPro;
  background-color: $seeButton;
  color: $pureWhite;
  width: 15vw;
  height: 3.3vw;
  border-radius: 50px;
  font-size: 1.2vw;
  border: none;
  margin: auto;
  margin-top: 2vw;
  font-size: 1.2vw;
  cursor: pointer;
}
.link{
  text-decoration: none;
}