@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.container {
  padding-top: 8.5vw;
  background-size: cover;
}
.uppper {
  display: flex;
  width: 100%;
  justify-content: center;
  color: white;
}
.title {
  font-family: "AndadaPro";
  font-size: 4.3vw;
  font-weight: 700;
  align-items: center;
  text-align: center;
  margin: 80px 9vw 0 9vw;
}
.middle {
  display: flex;
  justify-content: right;
  margin-top: 5vw;
}
.logoContent{
  margin-right: 5vw;
  margin-top: 2vw;
}
.logoTitle {
  color: white;
  font-family: "BerkshireSwash";
  font-size: 4.3vw;
  font-weight: 700;
  align-items: center;
  text-align: center;
  margin-left: 21vw;
}
.logoImage {
  float: right;
}
.lower {
  display: flex;
  justify-content: center;
  margin-top: 5vw;
  padding-bottom: 1vw;
}
.navi {
  font-family: "AndadaPro";
  display: flex;
  color: white;
  text-align: center;
  align-items: center;
  margin-left: 2vw;
  margin-right: 2vw;
}
.icon {
  margin-right: 1vw;
}