@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.container {
  margin-top: -10px;
  width: 100%;
  height: 64vw;
  background-size: cover;
}
.logoImage {
  width: 150px;
  height: auto;
}
.logoBar {
  display: flex;
  align-items: center;
  padding-top: 60px;
  margin-left: 6vw;
  width: 30%;
}
.logoText {
  font-family: Berkshire Swash;
  color: $pureWhite;
  font-size: 3.7vw;
  font-weight: 400;
  margin-left: 12px;
}
.content {
  display: flex;
  color: $pureWhite;
}
.contentText {
  margin-top: 5vw;
  margin-left: 6vw;
}
.contentTitle {
  font-family: AndadaPro;
  font-size: 2.7vw;
}
.contentParagraph {
  font-family: AndadaPro;
  align-self: center;
  font-size: 1.6vw;
  width: 85%;
}
.contentImage {
  position: relative;
  width: 100%;
  margin-right: 40vw;
  min-width: 0;
  top: -11vw;
}
.imageContainer {
  width: 180%;
  display: flex;
  flex-direction: column;
}
.buttons {
  display: flex;
  margin-top: 7vw;
}
.seeButton {
  font-family: AndadaPro;
  background-color: $seeButton;
  color: $pureWhite;
  width: 234px;
  height: 64px;
  border-radius: 50px;
  font-size: 20px;
  border: none;
  margin-top: -3vw;
  cursor: pointer;
}
.storeButton {
  color: $pureWhite;
  background-color: $pureBlack;
}
.downloadButtons {
  margin-top: -9vw;
  display: flex;
  justify-content: space-around;
  width: 450px;
  align-self: center;
}
.playButton {
  color: $pureWhite;
  background-color: $pureBlack;
}
