@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.container {
  text-align: center;
  color: $greyTextColor;
}
.content {
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}
.title {
  font-family: "AndadaPro";
  font-size: 3.2vw;
}
.paragraph {
  font-family: "AndadaPro";
  font-size: 1.5vw;
  width: 80%;
  margin: auto;
  margin-bottom: 4.5vw;
  margin-top: 2vw;
}
.imageContainer {
  position: relative;
  margin: auto;
}
.videoContainer {
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 35px;
  width: 322px;
  height: 607px;
  overflow: hidden;
  left: 2px;
  right: 0px;
  top: 0;
  bottom: 5px;
  margin: auto;
}
.video {
  display: block;
  margin: 0 auto;
  object-fit: cover;
  width: 100%; height: 100%;
}
.image {
  position: relative;
}
.playButton {
  width: 30%;
  position: absolute;
  top: 40%;
}
