@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.title {
  text-align: center;
  font-family: "AndadaPro";
  color: $greyTextColor;
  font-size: 3.1vw;
  margin-top: 5vw;
}
.container {
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
  justify-content: center;
}
.paragraph {
  font-family: "AndadaPro";
  font-size: 1.5vw;
  width: 80%;
  margin: auto;
  margin-bottom: 4.5vw;
  margin-top: 2vw;
  text-align: center;
  color: $greyTextColor;
}
.screenshots {
  display: flex;
  width: 84%;
  overflow: hidden;
}
.arrow_left {
  width: 2vw;
  height: auto;
  z-index: 10px;
  margin-bottom: 4vw;
  cursor: pointer;
}
.arrow_right {
  padding-left: 1vw;
  width: 2vw;
  height: auto;
  z-index: 10px;
  margin-bottom: 4vw;
  cursor: pointer;
}
.dotsContainer {
  display: flex;
  justify-content: center;
}
.dots {
  display: flex;
}
.dot {
  display: block;
  background-color: #3b4153;
  width: 14px;
  height: 14px;
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  transition: width 1s;
}
.line {
  display: block;
  background-color: #3b4153;
  width: 28px;
  height: 14px;
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  transition: width 0.5s;
}
.seeButton {
  display: block;
  font-family: AndadaPro;
  background-color: $seeButton;
  color: $pureWhite;
  width: 234px;
  height: 64px;
  border-radius: 50px;
  font-size: 1.2vw;
  border: none;
  margin: auto;
}
