@import '../../core/theme/colors.scss';

.container {
  background-color: $pureBlack;
  display: flex;
  justify-content: space-around;
  height: 57px;
  width: 168px;
  border: 0.01px solid $buttonBorderGrey;
  border-radius: 10px;
}

a {
  color: #0060B6;
  text-decoration: none;
}

a:hover {
  color:#00A0C6; 
  text-decoration:none; 
  cursor:pointer;  
}

.image {
  width: 34px;
  height: auto;
  margin-right: -15px;
}
.content {
  color: $pureWhite;
  display: flex;
  flex-direction: column;
}
.paragraph {
  margin-top: 0.4vw;
  margin-bottom: 0px;
  font-size: 11px;
}
.title {
  margin-top: 0;
  font-size: 20px;
}
.inner {
  transition: transform 2s;
}
.photo {
  width: 20vw;
  display: flex;
}
.name {
  font-family: "AndadaPro";
  color: $greyTextColor;
  font-size: 1.3vw;
  text-align: center;
  margin-bottom: 0.1vw;
  margin-top: 0.1vw;
}
.names {
  margin-top: 1.6vw;
}
.imageOurTeam {
  width: 15.2vw;
  height: auto;
  margin: auto;
}
.images {
  display: flex;
  flex-direction: column;
  margin-left: 1.34vw;
}