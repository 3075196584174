@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.container {
  align-items: center;
  border: 1px solid $greyTextColor;
  border-radius: 50px;
  width: 80%;
  margin: auto;
  margin-top: 5vw;
  margin-bottom: 5vw;
}
.text {
  text-align: center;
  margin-top: 3vw;
  color: $greyTextColor;
}
.title {
  font-family: "AndadaPro";
  font-size: 3.1vw;
}
.paragraph {
  font-family: "AndadaPro";
  font-size: 1.6vw;
  margin: auto;
  margin-top: 2vw;
  width: 80%;
}
.downloadButtons {
  padding-top: 5vw;
  padding-bottom: 2vw;
  display: flex;
  justify-content: space-around;
  width: 40%;
  align-self: center;
  margin: auto;
}
