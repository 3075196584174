@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.title {
  text-align: center;
  font-family: "AndadaPro";
  color: $greyTextColor;
  font-size: 3.1vw;
}
.container {
  display: flex;
  margin-top: 60px;
  margin-bottom: 140px;
  justify-content: center;
}
.screenshots {
  display: flex;
  width: 80.5%;
  overflow: hidden;
}
.arrow_left {
  width: 2vw;
  height: auto;
  z-index: 100px;
  cursor: pointer;
  padding-right: 2vw;
}
.arrow_right {
  padding-left: 2vw;
  width: 2vw;
  height: auto;
  z-index: 10px;
  cursor: pointer;
}
